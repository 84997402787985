<template>
    <div class="frame noselect" :class="{ highlight }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        highlight: { type: Boolean },
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
}
.frame.highlight {
    box-shadow: inset 0 0 15px 4px rgb(227, 182, 39);
    -webkit-box-shadow: inset 0 0 15px 4px rgb(227, 182, 39);
    -moz-box-shadow: inset 0 0 4px 2px rgb(227, 182, 39);
}
@media only screen and (max-width: 600px) {
    .frame.highlight {
        box-shadow: inset 0 0 8px 2px rgb(227, 182, 39);
        -webkit-box-shadow: inset 0 0 8px 2px rgb(227, 182, 39);
        -moz-box-shadow: inset 0 0 8px 2px rgb(227, 182, 39);
    }
}
</style>
