import { Controller } from '@/core/controller';
import { Card, Hint } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { gameService } from '@/state/game/game.service';
import { cardSound } from '@/core/sound';

export abstract class CardAutoMoveBaseController extends Controller {
    private isAutoFinishInProgress = false;

    protected abstract doBestMove(card: Card): void;

    protected abstract isAutoFinishPossible(): boolean;

    protected abstract doAutoFinish(done: () => void): void;

    protected abstract generateHints(): Hint[];

    constructor() {
        super();
        this.subscribeTo(coreBus.cardClickEvent$, (ev) => {
            this.doBestMove(ev.card);
        });
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, () => {
            if (this.isAutoFinishPossible() && !this.isAutoFinishInProgress) {
                gameService.setCanAutoFinish(true);
            }
        });
        this.subscribeTo(coreBus.autoFinishCmd$, () => {
            if (this.isAutoFinishPossible()) {
                this.isAutoFinishInProgress = true;
                cardSound.startAutoFinish();
                this.doAutoFinish(() => {
                    this.isAutoFinishInProgress = false;
                    cardSound.endAutoFinish();
                });
            }
        });
        this.subscribeTo(coreBus.generateHintCmd$, () => {
            const hints = this.generateHints();
            gameService.setHints(hints);
            if (hints.length == 0) {
                coreBus.showNotificationCmd$.next({
                    text: 'No hints available',
                    type: 'success',
                });
            }
        });
    }
}
