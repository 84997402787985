<template>
    <div
        class="frame stock noselect roeiwag"
        :class="classes"
        :style="stockStyle"
        @click="stockClick"
    >
        <icon
            v-show="cardCount === 0 && enableRecycle"
            icon="undo"
            :style="restartIconStyle"
        ></icon>
        <img v-show="cardCount > 0" :src="cardImage" :style="imageStyle" alt="card-back" />
        <div v-show="cardCount > 0" class="stock-counter" :style="stockCounterStyle">
            {{ cardCount }}
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { CardOwner } from '@/core/models';
import { subscribeTo } from '@/core/rxjs-helpers';
import Icon from '@/components/Icon.vue';
import { coreBus } from '@/core/core-bus';
import { cardImages } from '@/core/card-images';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameQuery } from '@/state/game/game.query';
import { recomputable } from '@/composable/recomputable';
import { cardDisplayFactory } from '@/core/display';

export default defineComponent({
    components: {
        Icon,
    },

    props: {
        enableRecycle: { type: Boolean },
    },

    setup(props) {
        const cardCount = ref(0);
        const recompute = ref(0);

        subscribeTo(cardsQuery.selectAll(), (cards) => {
            cardCount.value = cards.filter((c) => c.owner == CardOwner.stock).length;
        });

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const stockStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
                borderRadius: `${cardDisplayFactory.get.cardSize.width * 0.06}px`,
                lineHeight: `${cardDisplayFactory.get.cardSize.height}px`,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            const s = cardDisplayFactory.get.cardSize.width * 0.3;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const stockClick = () => {
            if (cardCount.value > 0) {
                coreBus.stockClickEvent$.next();
            } else {
                coreBus.recycleWasteCmd$.next();
            }
        };

        const cardImage = computed(() => {
            return cardImages.back;
        });

        const imageStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height - 1}px`,
            };
        });

        const classes = computed(() => {
            const arr = [];
            if (cardCount.value > 0) {
                arr.push('card');
            }
            if (props.enableRecycle || cardCount.value > 0) {
                arr.push('clickable');
            }
            return arr;
        });

        const stockCounterStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
                lineHeight: `${cardDisplayFactory.get.cardSize.height}px`,
                fontSize: `${cardDisplayFactory.get.cardSize.width * 0.4}px`,
            };
        });

        return {
            stockStyle,
            restartIconStyle,
            stockClick,
            cardImage,
            imageStyle,
            classes,
            cardCount,
            stockCounterStyle,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: relative;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
}
.frame.clickable {
    pointer-events: all;
    cursor: pointer;
}
.frame.stock {
    text-align: center;
    vertical-align: center;
}
.frame.card {
    background-color: #eeeeee;
    box-shadow: -5px 5px 6px -4px rgba(0, 0, 0, 0.5);
}
.stock-counter {
    color: #eee;
    top: -1px;
    left: -1px;
    position: absolute;
    z-index: 99;
    pointer-events: none;
}
@media only screen and (max-width: 600px) {
    .frame.card {
        box-shadow: -3px 3px 4px -4px rgba(0, 0, 0, 0.5);
    }
}
</style>
