import { Controller } from '@/core/controller';
import { Card, Position } from '@/core/models';
import { CardDragEvent, coreBus, DragEventType } from '@/core/core-bus';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameService } from '@/state/game/game.service';
import { cardDisplayFactory } from '@/core/display';

type FrameIntersect = {
    frames: () => { position: Position; index: number }[];
    validate: (card: Card, index: number) => boolean;
    trigger: (card: Card, index: number) => void;
};

type TableauCardIntersect = {
    canPutCardOnTopOf?: (card: Card, dest: Card) => boolean | undefined;
};

type FoundationIntersect = {
    frames: () => { position: Position; index: number }[];
    validate: (card: Card, index: number) => boolean;
};

type TableauIntersect = {
    frames: () => { position: Position; index: number }[];
    validate: (card: Card, index: number) => boolean;
};

type Options = {
    tableauCardIntersect?: TableauCardIntersect | undefined;
    foundationIntersect?: FoundationIntersect | undefined;
    tableauIntersect?: TableauIntersect | undefined;
    otherIntersect?: FrameIntersect[] | undefined;
};

export class CardDragBaseController extends Controller {
    private readonly options: Options;

    private topTableauCards: Card[] = [];

    private currentHighlightId = '';

    private currentFoundationIndexHighlight = 0;

    constructor(options: Options) {
        super();
        this.options = options;
        this.subscribeTo(coreBus.cardDragEvent$, (ev) => {
            this.handleEvent(ev);
        });
    }

    private handleEvent(ev: CardDragEvent) {
        if (ev.type == DragEventType.start) {
            this.doDragStart(ev);
        }
        if (ev.type == DragEventType.end) {
            this.doDragEnd(ev);
        }
        if (ev.type == DragEventType.move) {
            this.doDragMove(ev);
        }
    }

    private doDragStart(ev: CardDragEvent) {
        cardsService.update(ev.cardId, {
            isDragging: true,
        });

        // we want to get top tableau cards so we can use it in intersect check
        if (this.options.tableauIntersect) {
            this.topTableauCards = cardsQuery.getTopTableauCards();
        }
    }

    private doDragEnd(ev: CardDragEvent) {
        const card = ev.card;

        cardsService.update(card.id, {
            isDragging: false,
        });

        this.removeCardHighlight();
        this.removeFoundationHighlight();

        if (this.options.tableauCardIntersect) {
            const intCard = this.getValidTableauIntersectedCard(ev);
            if (intCard) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: intCard,
                });
                return;
            }
        }

        if (this.options.foundationIntersect) {
            const intFound = this.getValidFrameIntersected(ev, this.options.foundationIntersect);
            if (intFound) {
                coreBus.moveCardToFoundationCmd$.next({
                    card,
                    foundationIndex: intFound,
                });
                return;
            }
        }

        if (this.options.tableauIntersect) {
            const intTab = this.getValidFrameIntersected(ev, this.options.tableauIntersect);
            if (intTab) {
                coreBus.moveCardToEmptyTableauCmd$.next({
                    card,
                    tableauIndex: intTab,
                });
                return;
            }
        }

        if (this.options.otherIntersect) {
            this.options.otherIntersect.forEach((options) => {
                const int = this.getValidFrameIntersected(ev, options);
                if (int) {
                    options.trigger(card, int);
                    return;
                }
            });
        }

        this.moveCardToOrigin(card);
    }

    private doDragMove(ev: CardDragEvent) {
        if (this.options.tableauCardIntersect) {
            const cardInt = this.getValidTableauIntersectedCard(ev);
            if (cardInt) {
                this.setCardHighlight(cardInt.id);
                return;
            } else {
                this.removeCardHighlight();
            }
        }

        if (this.options.foundationIntersect) {
            const foundInt = this.getValidFrameIntersected(ev, this.options.foundationIntersect);
            if (foundInt) {
                this.setFoundationHighlight(foundInt);
            } else {
                this.removeFoundationHighlight();
            }
        }
    }

    protected setCardHighlight(id: string) {
        if (this.currentHighlightId === id) {
            return;
        }
        if (this.currentHighlightId != '') {
            this.removeCardHighlight();
        }
        cardsService.update(id, {
            isHighlight: true,
        });
        this.currentHighlightId = id;
    }

    private removeCardHighlight() {
        if (this.currentHighlightId != '') {
            cardsService.update(this.currentHighlightId, {
                isHighlight: false,
            });
            this.currentHighlightId = '';
        }
    }

    protected setFoundationHighlight(ownerIndex: number) {
        if (this.currentFoundationIndexHighlight === ownerIndex) {
            return;
        }
        if (this.currentFoundationIndexHighlight != 0) {
            this.removeFoundationHighlight();
        }
        gameService.setFoundationHighlight(ownerIndex);
        this.currentFoundationIndexHighlight = ownerIndex;
    }

    private removeFoundationHighlight() {
        if (this.currentFoundationIndexHighlight != 0) {
            gameService.removeFoundationHighlight();
            this.currentFoundationIndexHighlight = 0;
        }
    }

    private getValidTableauIntersectedCard(ev: CardDragEvent): Card | null {
        const cardW = cardDisplayFactory.get.cardSize.width;
        const cardH = cardDisplayFactory.get.cardSize.height;
        const card = ev.card;
        const canPutCardOnTopOf = this.options.tableauCardIntersect
            ? this.options.tableauCardIntersect.canPutCardOnTopOf
            : null;

        if (!canPutCardOnTopOf) {
            return null;
        }

        // get top cards that intersects with the dragged card
        const intersects = [];
        for (let i = 0; i < this.topTableauCards.length; i++) {
            const top = this.topTableauCards[i];
            if (top.id == ev.cardId) {
                continue;
            }
            // check if intersect
            const topPos = cardDisplayFactory.get.calcCardPosition(top);
            if (
                !(
                    topPos.x > ev.x + cardW ||
                    topPos.x + cardW < ev.x ||
                    topPos.y > ev.y + cardH ||
                    topPos.y + cardH < ev.y
                )
            ) {
                if (canPutCardOnTopOf(card, top)) {
                    intersects.push(top);
                }
            }
        }

        // if more then one intersect get the closest one
        if (intersects.length == 0) {
            return null;
        }
        let selected = intersects[0];
        if (intersects.length > 1) {
            for (let i = 1; i < intersects.length; i++) {
                const int = intersects[i];
                const intPosition = cardDisplayFactory.get.calcCardPosition(int);
                const selectedPosition = cardDisplayFactory.get.calcCardPosition(selected);
                if (Math.abs(intPosition.x - ev.x) < Math.abs(selectedPosition.x - ev.x)) {
                    selected = int;
                }
            }
        }

        return selected;
    }

    private getValidFrameIntersected(
        ev: CardDragEvent,
        options: FrameIntersect | FoundationIntersect
    ) {
        const frames = options.frames();
        for (let i = 0; i < frames.length; i++) {
            const item = frames[i];
            if (
                this.isIntersectedWithFrame(ev, item.position) &&
                options.validate(ev.card, item.index)
            ) {
                return item.index;
            }
        }

        return null;
    }

    protected isIntersectedWithFrame(ev: CardDragEvent, framePos: Position) {
        const cardW = cardDisplayFactory.get.cardSize.width;
        const cardH = cardDisplayFactory.get.cardSize.height;
        return !(
            framePos.x > ev.x + cardW ||
            framePos.x + cardW < ev.x ||
            framePos.y > ev.y + cardH ||
            framePos.y + cardH < ev.y
        );
    }

    private moveCardToOrigin(card: Card) {
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
        // move cards below as well
        let nextOrder = card.order + 1;
        while (nextOrder > 0) {
            const belowCard = cardsQuery.getCardByOwnerAndIndexAndOrder(
                card.owner,
                card.ownerIndex,
                nextOrder
            );
            if (belowCard) {
                coreBus.cardMoveCmd$.next({
                    cardId: belowCard.id,
                    duration: 300,
                });
                nextOrder++;
            } else {
                nextOrder = 0;
            }
        }
    }
}
