import { Controller } from '@/core/controller';
import {
    coreBus,
    MeldCardCmd,
    MoveCardToEmptyTableauCmd,
    MoveCardToFoundationCmd,
} from '@/core/core-bus';
import { CardOwner, GameStatus } from '@/core/models';
import { moveHistory } from '@/core/move-history';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameService } from '@/state/game/game.service';
import { gameQuery } from '@/state/game/game.query';
import { cardSound } from '@/core/sound';

type MoveOption<T> = {
    validate: (cmd: T) => boolean;
    before?: (cmd: T) => void | undefined;
    after?: (cmd: T) => void | undefined;
};

type Options = {
    enableTableauAutoAdjust: boolean;
    meldCard?: MoveOption<MeldCardCmd> | undefined;
    moveCardToFoundation?: MoveOption<MoveCardToFoundationCmd> | undefined;
    moveCardToEmptyTableauCmd?: MoveOption<MoveCardToEmptyTableauCmd> | undefined;
};

export abstract class CardMoveBaseController extends Controller {
    private readonly options: Options;

    constructor(options: Options) {
        super();
        this.options = options;
        if (options.meldCard) {
            this.subscribeTo(coreBus.meldCardCmd$, (cmd) => {
                // eslint-disable-next-line
                this.meldCard(cmd, options.meldCard!);
                coreBus.gameMoveCompletedEvent$.next();
            });
        }
        if (options.moveCardToFoundation) {
            this.subscribeTo(coreBus.moveCardToFoundationCmd$, (cmd) => {
                // eslint-disable-next-line
                this.moveCardToFoundation(cmd, options.moveCardToFoundation!);
                coreBus.gameMoveCompletedEvent$.next();
            });
        }
        if (options.moveCardToEmptyTableauCmd) {
            this.subscribeTo(coreBus.moveCardToEmptyTableauCmd$, (cmd) => {
                // eslint-disable-next-line
                this.moveCardToEmptyTableau(cmd, options.moveCardToEmptyTableauCmd!);
                coreBus.gameMoveCompletedEvent$.next();
            });
        }
        this.subscribeTo(coreBus.undoMoveCmd$, () => {
            this.undoLastMove();
            coreBus.gameMoveCompletedEvent$.next();
        });
    }

    private async meldCard(cmd: MeldCardCmd, options: MoveOption<MeldCardCmd>) {
        if (!this.canMakeMove()) {
            return;
        }
        if (!options.validate(cmd)) {
            return;
        }

        // eslint-disable-next-line
        const cardsAffected: any[] = [];

        moveHistory.startMove();

        if (options.before) {
            options.before(cmd);
        }

        // meld card into new tableau
        cardsService.update(cmd.card.id, {
            order: cmd.destCard.order + 1,
            owner: cmd.destCard.owner,
            ownerIndex: cmd.destCard.ownerIndex,
            isDragging: false,
        });
        cardsAffected.push(cmd.card);
        moveHistory.addState(cmd.card);

        // update cards bellow current
        const bellowUpdates = cardsQuery
            .getByOwnerAndIndexGreaterThenOrder(cmd.card.owner, cmd.card.ownerIndex, cmd.card.order)
            .map((card, i) => {
                cardsAffected.push(card);
                moveHistory.addState(card);
                return {
                    ...card,
                    order: cmd.destCard.order + 2 + i,
                    owner: cmd.destCard.owner,
                    ownerIndex: cmd.destCard.ownerIndex,
                    isDragging: false,
                };
            });
        cardsService.upsertMany(bellowUpdates);
        if (this.options.enableTableauAutoAdjust) {
            cardsService.updateTableauCount();
        }

        // if card below the card we want to flip the bellow card
        if (cmd.card.order > 1) {
            const parent = cardsQuery.getCardByOwnerAndIndexAndOrder(
                cmd.card.owner,
                cmd.card.ownerIndex,
                cmd.card.order - 1
            );
            if (parent) {
                cardsService.update(parent.id, {
                    isFaceUp: true,
                    dragEnabled: true,
                });
                moveHistory.addState(parent);
            }
        }

        if (options.after) {
            options.after(cmd);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        // move all cards to new positions
        cardsAffected.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 300,
            });
        });

        if (this.options.enableTableauAutoAdjust) {
            this.moveTableauCards(
                cardsAffected.map((c) => c.id),
                cmd.card.ownerIndex,
                cmd.destCard.ownerIndex
            );
        }

        // play sound
        cardSound.playMove();
    }

    private moveCardToFoundation(
        cmd: MoveCardToFoundationCmd,
        options: MoveOption<MoveCardToFoundationCmd>
    ) {
        if (!this.canMakeMove()) {
            return;
        }
        if (!options.validate(cmd)) {
            return;
        }

        moveHistory.startMove();

        if (options.before) {
            options.before(cmd);
        }

        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, cmd.foundationIndex);
        const order = top ? top.order + 1 : 1;
        cardsService.update(cmd.card.id, {
            owner: CardOwner.foundation,
            ownerIndex: cmd.foundationIndex,
            order,
            dragEnabled: true,
        });
        moveHistory.addState(cmd.card);
        coreBus.cardMoveCmd$.next({
            cardId: cmd.card.id,
            duration: 300,
        });

        // card come from tableau we want to flip the new top
        if (cmd.card.owner == CardOwner.tableau || cmd.card.owner == CardOwner.reserve) {
            const top = cardsQuery.getTopByOwnerAndIndex(cmd.card.owner, cmd.card.ownerIndex);
            if (top) {
                cardsService.update(top.id, {
                    isFaceUp: true,
                    dragEnabled: true,
                });
                moveHistory.addState(top);
            }
        }

        if (options.after) {
            options.after(cmd);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        if (this.options.enableTableauAutoAdjust) {
            cardsService.updateTableauCount();
            this.moveTableauCards([], cmd.card.ownerIndex);
        }

        // play sound
        cardSound.playScore();
    }

    private moveCardToEmptyTableau(
        cmd: MoveCardToEmptyTableauCmd,
        options: MoveOption<MoveCardToEmptyTableauCmd>
    ) {
        if (!this.canMakeMove()) {
            return true;
        }
        if (!options.validate(cmd)) {
            return;
        }

        const cardsAffected = [];
        moveHistory.startMove();

        if (options.before) {
            options.before(cmd);
        }

        // meld card into new tableau
        cardsService.update(cmd.card.id, {
            order: 1,
            owner: CardOwner.tableau,
            ownerIndex: cmd.tableauIndex,
            isDragging: false,
        });
        cardsAffected.push(cmd.card);
        moveHistory.addState(cmd.card);

        // update card bellow
        let nextOrderOld = cmd.card.order + 1;
        let nextOrderNew = 2;
        while (nextOrderOld > 0) {
            const belowCard = cardsQuery.getCardByOwnerAndIndexAndOrder(
                cmd.card.owner,
                cmd.card.ownerIndex,
                nextOrderOld
            );
            if (belowCard) {
                cardsService.update(belowCard.id, {
                    order: nextOrderNew,
                    owner: CardOwner.tableau,
                    ownerIndex: cmd.tableauIndex,
                    isDragging: false,
                });
                cardsAffected.push(belowCard);
                moveHistory.addState(belowCard);
                nextOrderOld++;
                nextOrderNew++;
            } else {
                nextOrderOld = 0;
            }
        }

        // if card below the card we want to flip the bellow card
        if (cmd.card.order > 1) {
            const parent = cardsQuery.getCardByOwnerAndIndexAndOrder(
                cmd.card.owner,
                cmd.card.ownerIndex,
                cmd.card.order - 1
            );
            if (parent) {
                cardsService.update(parent.id, {
                    isFaceUp: true,
                    dragEnabled: true,
                });
                moveHistory.addState(parent);
            }
        }

        if (options.after) {
            options.after(cmd);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        // move all cards to new positions
        cardsAffected.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 300,
            });
        });

        if (this.options.enableTableauAutoAdjust) {
            cardsService.updateTableauCount();
            this.moveTableauCards(
                cardsAffected.map((c) => c.id),
                cmd.card.ownerIndex,
                cmd.tableauIndex
            );
        }

        // play sound
        cardSound.playMove();
    }

    private undoLastMove() {
        if (!this.canMakeMove()) {
            return true;
        }

        const move = moveHistory.pop();
        if (move) {
            cardsService.upsertMany(move.cards);
            if (this.options.enableTableauAutoAdjust) {
                cardsService.updateTableauCount();
            }
            move.cards.forEach((c) => {
                coreBus.cardMoveCmd$.next({
                    cardId: c.id,
                    duration: 300,
                });
            });
            gameService.increaseMoveCounter();
            gameService.increaseUndoCount();

            if (this.options.enableTableauAutoAdjust) {
                this.moveTableauCards(move.cards.map((c) => c.id));
            }
        }

        // play sound
        cardSound.playUndo();
    }

    protected canMakeMove() {
        const status = gameQuery.getValue().gameStatus;
        return status == GameStatus.running || status == GameStatus.dealCompleted;
    }

    protected moveTableauCards(
        ignoreIds?: string[] | undefined,
        fromIndex?: number | undefined,
        toIndex?: number | undefined
    ) {
        if (!fromIndex && !toIndex) {
            cardsQuery.getAllByOwner(CardOwner.tableau).forEach((c) => {
                if (!ignoreIds || ignoreIds.indexOf(c.id) < 0) {
                    coreBus.cardMoveCmd$.next({
                        cardId: c.id,
                        duration: 300,
                    });
                }
            });
        }
        if (toIndex) {
            cardsQuery.getAllByOwnerAndIndex(CardOwner.tableau, toIndex).forEach((c) => {
                if (!ignoreIds || ignoreIds.indexOf(c.id) < 0) {
                    coreBus.cardMoveCmd$.next({
                        cardId: c.id,
                        duration: 300,
                    });
                }
            });
        }
        if (fromIndex) {
            cardsQuery.getAllByOwnerAndIndex(CardOwner.tableau, fromIndex).forEach((c) => {
                coreBus.cardMoveCmd$.next({
                    cardId: c.id,
                    duration: 300,
                });
            });
        }
    }
}
