
import { computed, defineComponent, ref } from 'vue';
import { CardOwner } from '@/core/models';
import { subscribeTo } from '@/core/rxjs-helpers';
import Icon from '@/components/Icon.vue';
import { coreBus } from '@/core/core-bus';
import { cardImages } from '@/core/card-images';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameQuery } from '@/state/game/game.query';
import { recomputable } from '@/composable/recomputable';
import { cardDisplayFactory } from '@/core/display';

export default defineComponent({
    components: {
        Icon,
    },

    props: {
        enableRecycle: { type: Boolean },
    },

    setup(props) {
        const cardCount = ref(0);
        const recompute = ref(0);

        subscribeTo(cardsQuery.selectAll(), (cards) => {
            cardCount.value = cards.filter((c) => c.owner == CardOwner.stock).length;
        });

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const stockStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
                borderRadius: `${cardDisplayFactory.get.cardSize.width * 0.06}px`,
                lineHeight: `${cardDisplayFactory.get.cardSize.height}px`,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            const s = cardDisplayFactory.get.cardSize.width * 0.3;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const stockClick = () => {
            if (cardCount.value > 0) {
                coreBus.stockClickEvent$.next();
            } else {
                coreBus.recycleWasteCmd$.next();
            }
        };

        const cardImage = computed(() => {
            return cardImages.back;
        });

        const imageStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height - 1}px`,
            };
        });

        const classes = computed(() => {
            const arr = [];
            if (cardCount.value > 0) {
                arr.push('card');
            }
            if (props.enableRecycle || cardCount.value > 0) {
                arr.push('clickable');
            }
            return arr;
        });

        const stockCounterStyle = recomputable(recompute, () => {
            return {
                width: `${cardDisplayFactory.get.cardSize.width}px`,
                height: `${cardDisplayFactory.get.cardSize.height}px`,
                lineHeight: `${cardDisplayFactory.get.cardSize.height}px`,
                fontSize: `${cardDisplayFactory.get.cardSize.width * 0.4}px`,
            };
        });

        return {
            stockStyle,
            restartIconStyle,
            stockClick,
            cardImage,
            imageStyle,
            classes,
            cardCount,
            stockCounterStyle,
        };
    },
});
